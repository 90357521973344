@keyframes spinnerrfi6tkbefore {
  0% {
    transform: rotate(30deg);
  }

  50%,
  90%,
  100% {
    border-right-color: #ecedff;
    transform: rotate(330deg);
  }
}
@keyframes spinnerrfi6tkbeforeDark {
  0% {
    transform: rotate(30deg);
  }

  50%,
  90%,
  100% {
    border-right-color: #353536;
    transform: rotate(330deg);
  }
}
@keyframes spinnerrfi6tkafter {
  0% {
    transform: rotate(30deg);
  }

  50%,
  90%,
  100% {
    border-left-color: #ecedff;
    transform: rotate(330deg);
  }
}
@keyframes spinnerrfi6tkafterDark {
  0% {
    transform: rotate(30deg);
  }

  50%,
  90%,
  100% {
    border-left-color: #353536;
    transform: rotate(330deg);
  }
}
/* Only Safari */
@media not all and (min-resolution: 0.001dpcm) {
  .spinner .g-d {
    margin-left: -14px !important;
  }
  .spinner .t-d {
    margin-left: -8px !important;
    margin-top: -14px !important;
  }
  .spinner .b-d {
    margin-top: 16px !important;
    margin-left: 8px !important;
  }
}
